import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '登录',
        meta: {
            title: '成都睿豹网络科技-面试信息管理系统'
        },
        component: () => import('../views/Login.vue')
    },
    //下面路径映射分了children和菜单的分级无关，只是说明下面的所有路径映射都是再/首页中的，所以写道了childern里
    {
        path: '/Home',
        name: '首页',
        meta: {
            title: '成都睿豹网络科技-面试信息管理系统'
        },
        component: () => import('../views/HomeView.vue'),
        children: [
            {
                path: '/menu/menu',
                name: '菜单管理',
                component: () => import('../views/menu/menu.vue'),
            },
            {
                path: '/usermanage/userinfos',
                name: '账号管理',
                component: () => import('../views/usermanage/userinfos.vue'),
            },
            {
                path: '/role/role',
                name: '角色管理',
                component: () => import('../views/role/role.vue'),
            },
            {
                path: '/configuration/configuration',
                name: '系统配置',
                component: () => import('../views/configuration/configuration.vue'),
            },
            {
                path: '/configuration/unitconfiguration',
                name: '基础配置',
                component: () => import('../views/configuration/unitconfiguration.vue'),
            },
            {
                path: '/configuration/txtconfiguration',
                name: '状态配置',
                component: () => import('../views/configuration/txtconfiguration.vue'),
            },
            {
                path: '/configuration/data',
                name: '面试材料项配置',
                component: () => import('../views/configuration/data.vue'),
            },
            {
                path: '/usermanage/usermanage',
                name: '管理员管理',
                component: () => import('../views/usermanage/usermanage.vue'),
            },
            {
                path: '/unit/unit',
                name: '单位列表',
                component: () => import('../views/role/unit.vue'),
            },
            {
                path: '/joblist/joblist',
                name: '全部职位',
                component: () => import('../views/role/joblist.vue'),
            },
            {
                path: '/jobdetail/jobdetail',
                meta:{isfan:true},
                name: '职位详情',
                component: () => import('../views/role/jobdetail.vue'),
            },
            {
                path: '/joblist/importjobs',
                name: '导入职位信息',
                meta:{isfan:true},
                component: () => import('../views/role/importjobs.vue'),
            },
            {
                path: '/joblist/unimportjobs',
                name: '职位信息导入失败记录',
                meta:{isfan:true},
                component: () => import('../views/role/unimportjobs.vue'),
            },
            // {
            //     path: '/usermanage/consumer',
            //     name: '用户管理',
            //     component: () => import('../views/usermanage/consumer.vue'),
            // },
            {
                path: '/examinee/examinee',
                name: '面试信息管理',
                meta: {keepAlive: true },
                component: () => import('../views/examinee/examinee.vue'),
            },
            {
                path: '/examinee/adminexaminee',
                name: '平台面试信息管理',
                meta: {keepAlive: true },
                component: () => import('../views/examinee/adminexaminee.vue'),
            },
            {
                path: '/examinee/importexaminee',
                name: '导入面试信息',
                meta:{isfan:true},
                component: () => import('../views/examinee/importexaminee.vue'),
            },
            {
                path: '/examinee/unimportexaminee',
                name: '面试信息导入失败记录',
                meta:{isfan:true},
                component: () => import('../views/examinee/unimportexaminee.vue'),
            },
            {
                path: '/students/students',
                name: '全部考生',
                meta: {keepAlive: true },
                component: () => import('../views/students/students.vue'),
            },
            {
                path: '/updates/updatestudent',
                name: '考生信息修改',
                meta: {keepAlive: true },
                component: () => import('../views/updates/updatestudent.vue'),
            },
            {
                path: '/updates/updateallstudent',
                name: '考生信息管理',
                meta: {keepAlive: true },
                component: () => import('../views/updates/updateallstudent.vue'),
            },
            {
                path: '/updates/updatestudentDetail',
                name: '修改考生信息详情',
                meta:{isfan:true},
                component: () => import('../views/updates/updatestudentDetail.vue'),
            },
            {
                path: '/students/importstudent',
                name: '导入考生信息',
                meta:{isfan:true},
                component: () => import('../views/students/importstudent.vue'),
            },
            {
                path: '/students/unimportstudent',
                name: '考生信息导入失败记录',
                meta:{isfan:true},
                component: () => import('../views/students/unimportstudent.vue'),
            },
            {
                path: '/students/auditfirst',
                name: '一审列表',
                meta: {keepAlive: true },
                component: () => import('../views/students/auditfirst.vue'),
            },
            {
                path: '/students/viewstudent',
                meta:{isfan:true},
                name: '考生详情信息',
                component: () => import('../views/students/viewstudent.vue'),
            },
            {
                path: '/students/studentDetail',
                meta:{isfan:true},
                name: '一审考生详情信息',
                component: () => import('../views/students/studentDetail.vue'),
            },
            {
                path: '/students/twostudentDetail',
                meta:{isfan:true},
                name: '二审考生详情信息',
                component: () => import('../views/students/twostudentDetail.vue'),
            },
            {
                path: '/students/auditsecend',
                name: '二审列表',
                meta: {keepAlive: true },
                component: () => import('../views/students/auditsecend.vue'),
            },
            {
                path: '/students/abandonstudent',
                name: '放弃面试考生',
                meta: {keepAlive: true },
                component: () => import('../views/students/abandonstudent.vue'),
            },
            {
                path: '/students/buabandonstudent',
                name: '待重传放弃考生',
                meta: {keepAlive: true },
                component: () => import('../views/students/buabandonstudent.vue'),
            },
            {
                path: '/students/uninfostudent',
                name: '未交资料考生',
                meta: {keepAlive: true },
                component: () => import('../views/students/uninfostudent.vue'),
            },
            {
                path: '/students/buinfostudent',
                name: '待补资料考生',
                meta: {keepAlive: true },
                component: () => import('../views/students/buinfostudent.vue'),
            },
            {
                path: '/students/nopassstudent',
                name: '审核不通过',
                meta: {keepAlive: true },
                component: () => import('../views/students/nopassstudent.vue'),
            },
            {
                path: '/students/passstudent',
                name: '审核通过',
                meta: {keepAlive: true },
                component: () => import('../views/students/passstudent.vue'),
            },
            {
                path: '/logs/managelog',
                name: '后台审查日志',
                component: () => import('../views/logs/managelog.vue'),
            },
            {
                path: '/logs/studentlog',
                name: '考生操作日志',
                component: () => import('../views/logs/studentlog.vue'),
            },
            {
                path: '/logs/updatelog',
                name: '信息修改日志',
                component: () => import('../views/logs/updatelog.vue'),
            },
            {
                path: '/logs/files',
                name: '下载文件管理',
                component: () => import('../views/logs/files.vue'),
            },
            {
                path: '/logs/filestore',
                name: '文件管理',
                component: () => import('../views/logs/filestore.vue'),
            },
            {
                path: '/statistics/jobstatistics',
                name: '职位审核详情',
                meta:{keepAlive:true},
                component: () => import('../views/statistics/jobstatistics.vue'),
            },
            {
                path: '/statistics/jobstatisDetail',
                name: '职位数据详情',
                meta:{isfan:true},
                component: () => import('../views/statistics/jobstatisDetail.vue'),
            },
            {
                path: '/texts/viewlog',
                name: '更新日志管理',
                component: () => import('../views/texts/viewlog.vue'),
            },
            {
                path: '/texts/helpbookemit',
                name: '帮助文档管理',
                component: () => import('../views/texts/helpbookemit.vue'),
            },
            {
                path: '/recycle/examineerecycle',
                name: '面试信息回收站',
                meta:{keepAlive:true},
                component: () => import('../views/recycle/examineerecycle.vue'),
            },
            {
                path: '/recycle/studentrecycle',
                name: '考生信息回收站',
                meta:{keepAlive:true},
                component: () => import('../views/recycle/studentrecycle.vue'),
            },
        ]
    },
    {
        path: '/texts/helpbook',
        meta: {
            title: '面试帮助文档'
        },
        name: '帮助文档管理',
        component: () => import('../views/texts/helpbook.vue'),
    },
    // {
    //     path: '/texts/text',
    //     meta: {
    //         title: '测试页面'
    //     },
    //     name: '测试页面',
    //     component: () => import('../views/texts/text.vue'),
    // },

]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
